import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import slugify from 'slugify';

import useBotStats from '../useBotStats';
import useBotPerformance from '../useBotPerformance';
import './BotDetails.scss';

import { Agent } from 'common/lib/apis/agentAPI';
import useViewPort from 'common/hooks/useViewPort';
import { getRepositoryHref, sanitizeUrl, shortenHash } from 'common/lib/utils';
import { Routes } from 'common/routes';
import Button from 'common/components-v2/Button/Button';
import {
  BranchIcon,
  DiscordIcon,
  ExternalLinkIcon,
  LicenseIcon,
  RadioIcon
} from 'common/components/Icons';
import Skeleton from 'react-loading-skeleton';
import Link from 'common/components-v2/Button/Link';
import LineChart from 'common/components-v2/Chart/Line/LineChart';
import { CssColor } from 'common/lib/css';
import { formatNumber } from 'forta-app/lib/utils';
import { developers } from 'forta-app/data/projects';
import PlanLabel from 'common/components-v2/PlanLabel/PlanLabel';
import { useAppDispatch, useAppSelector } from 'forta-app/app/hooks';
import { getUnlockConfig, paywall } from 'forta-app/data/plans';
import { MetricInterval } from 'forta-app/data/metrics';
import { trackEvent } from 'common/lib/analytics';
import { useConnectModal } from 'forta-app/components/wallet/useConnectModal';
import { subscribeToAgent } from 'forta-app/pages/NotificationsPage';
import { retrieveNotifications } from 'forta-app/slices/notificationSlice';
import Loader from 'common/components/Loader';
import { useDeleteNotificationModal } from 'forta-app/components/notifications/DeleteNotification';
import useBotPlan from 'common/hooks/useBotPlan';
import useBotChains from 'common/hooks/useBotChains';
import NetworkStatus from './NetworkStatus/NetworkStatus';
import useWallet from 'common/hooks/useWallet';

let startTimeInPage = 0;

function BotDetails(props: { bot?: Agent }): JSX.Element {
  const { bot } = props;

  const dispatch = useAppDispatch();
  const { web3React, jwt, ensureConnection } = useWallet();
  const { open: openConnectModal } = useConnectModal();
  const { notifications, notificationsLoading } = useAppSelector(
    (state) => state.notification
  );
  const { stats } = useBotStats({
    botId: bot?.id,
    timeframe: MetricInterval.Week,
    enabled: !!bot
  });
  const { open: openDeleteNotificationModal } = useDeleteNotificationModal();

  const descriptionElRef = useRef<HTMLParagraphElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false);

  const { chainIds, isChainIdsFetching } = useBotChains({ bot });

  const { isMdDown, width } = useViewPort();
  const { transactions, fetched: isTransactionsFetched } = useBotPerformance({
    botId: bot?.id || '',
    enabled: !!bot
  });

  useEffect(() => {
    startTimeInPage = Date.now();
  }, []);

  useEffect(() => {
    const descriptionEl = descriptionElRef.current;

    if (!descriptionEl || isExpanded) return;

    const hasOverflow = descriptionEl.scrollHeight > descriptionEl.clientHeight;

    setIsDescriptionTooLong(hasOverflow);
  }, [width, isExpanded, bot, descriptionElRef]);

  useEffect(() => {
    dispatch(retrieveNotifications({ jwt }));
  }, [jwt, dispatch]);

  // Make the chart to show past values
  const performanceMetrics = useMemo(() => {
    return transactions.slice(0, transactions.length - 1);
  }, [transactions]);

  const { plan, isFreePlan, isPlanPurchased } = useBotPlan({
    bot
  });

  const repositoryHref = getRepositoryHref(bot?.repository);
  const shortDescription =
    bot?.description ||
    'The developer has not yet provided a description for this bot.';
  const longDescription = useMemo(() => {
    if (!bot) return null;
    if (bot.long_description) return bot.long_description;

    const creationTime = moment(bot.created_at).fromNow();
    const updateTime = moment(bot.updated_at).fromNow();

    return (
      `This bot was created ${creationTime} and currently supports ${
        chainIds ?? 0
      } networks. ` +
      `Over the past 7 days, it has emitted ${
        stats?.alertCount ?? 0
      } alerts and ${stats?.labelCount ?? 0} labels. ` +
      `The last update was made ${updateTime}.`
    );
  }, [bot, chainIds, stats]);

  const developerName = useMemo(() => {
    if (!bot) return { name: '', link: '#' };

    const nameByDeveloper = new Map<string, string>(
      developers.map((d) => [d.address.toLowerCase(), d.name])
    );

    return {
      name:
        nameByDeveloper.get(bot.developer.toLowerCase()) ||
        shortenHash(bot.developer, 4),
      link: Routes.bots.index({ search: bot.developer.toLowerCase() })
    };
  }, [bot]);

  const subscription = notifications.find(
    (notification) =>
      notification.source.type === 'AGENT' &&
      notification.source.value === bot?.id
  );

  const handleBuyClick = async (): Promise<void> => {
    trackEvent(
      `data_plan_botpage_click_${slugify(plan?.name || '', { lower: true })}`,
      {}
    );

    // check if spent more than 60 seconds
    const timeSpent = Date.now() - startTimeInPage;
    if (timeSpent > 60000) {
      trackEvent(
        `buy_plan_click_after_1_minute_${slugify(plan?.name || '', {
          lower: true
        })}`,
        {}
      );
    }

    if (!plan) {
      return;
    }

    if (jwt) {
      await ensureConnection();

      const unlockConfig = getUnlockConfig([
        {
          ...plan,
          unlockAddresses: {
            USDC: plan.unlockAddresses['USDC'],
            FORT: plan.unlockAddresses['FORT'],
            trial: plan.unlockAddresses['trial']
          }
        }
      ]);
      paywall.connect(web3React.provider?.provider);
      paywall.loadCheckoutModal(unlockConfig);
    } else {
      openConnectModal();
    }
  };

  return (
    <div className="BotDetails">
      <div className="BotDetails__actions">
        {bot ? (
          <>
            {isFreePlan && !isPlanPurchased ? (
              <Button
                round
                variant="primary"
                size="md"
                className="BotDetails__action BotDetails__action--main"
                onClick={handleBuyClick}
              >
                Get free plan
              </Button>
            ) : isPlanPurchased && jwt ? (
              subscription ? (
                <Button
                  round
                  variant="outline"
                  size="md"
                  className="BotDetails__action BotDetails__action--main"
                  onClick={() => openDeleteNotificationModal(subscription.id)}
                >
                  Unsubscribe
                </Button>
              ) : (
                <Button
                  round
                  variant="primary"
                  size="md"
                  className="BotDetails__action BotDetails__action--main"
                  onClick={() =>
                    subscribeToAgent(bot.id, dispatch, web3React, () => {
                      // TODO: USE CONTEXT FOR THE CONNECT MODAL
                      // THIS NEVER HAPPENS BECAUSE THE USER NEEDS TO BE LOGGED IN TO SEE THIS BUTTON
                      console.log('');
                    })
                  }
                  disabled={notificationsLoading}
                >
                  {notificationsLoading ? <Loader /> : 'Subscribe'}
                </Button>
              )
            ) : (
              <>
                {plan?.price.USDC ? (
                  <div className="BotDetailsPrice">
                    <span className="BotDetailsPrice__value">
                      {formatNumber(plan?.price.USDC)} USDC
                    </span>
                    <span className="BotDetailsPrice__period">/ month</span>
                  </div>
                ) : plan?.price.FORT ? (
                  <div className="BotDetailsPrice">
                    <span className="BotDetailsPrice__value">
                      {formatNumber(plan?.price.FORT)} FORT
                    </span>
                    <span className="BotDetailsPrice__period">/ month</span>
                  </div>
                ) : null}
                <Button
                  round
                  variant="primary"
                  size="md"
                  className="BotDetails__action BotDetails__action--main"
                  onClick={handleBuyClick}
                >
                  Buy plan
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Skeleton
              height={46}
              width={160}
              borderRadius={50}
              className="BotDetails__action BotDetails__action--main"
            />
          </>
        )}
      </div>
      <div className="BotDetails__header">
        <div className="BotDetails__headings">
          {bot ? (
            <>
              <h1 className="BotDetails__title">{bot.name}</h1>
              {!isFreePlan && <PlanLabel planType={bot.subscription_type} />}
            </>
          ) : (
            <h1 className="BotDetails__title">
              <Skeleton inline width={isMdDown ? '80%' : 350} />
            </h1>
          )}
        </div>
        <div className="BotDetails__meta">
          {bot ? (
            <>
              {!bot?.enabled && (
                <div className="BotDetails__meta-item BotDetails__meta-item--status">
                  Disabled
                </div>
              )}
              <div className="BotDetails__meta-item">
                <span className="BotDetails__meta-item__prefix">by</span>{' '}
                <NavLink to={developerName.link}>{developerName.name}</NavLink>
              </div>
              <div className="BotDetails__meta-item">v{bot?.version}</div>
            </>
          ) : (
            <>
              <div className="BotDetails__meta-item">
                <Skeleton width={160} />
              </div>
              <div className="BotDetails__meta-item">
                <Skeleton width={90} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="BotDetails__body">
        <div className="BotDetails__body-content">
          <div className="BotDetails__networks">
            <NetworkStatus
              isExternalBot={bot?.external || false}
              isFetching={isChainIdsFetching}
              chainIds={chainIds}
            />
          </div>
          <p className="BotDetails__short-description">
            {bot ? (
              shortDescription
            ) : (
              <>
                <Skeleton width={'70%'} />
                <Skeleton width={'100%'} />
              </>
            )}
          </p>
          {bot ? (
            longDescription && (
              <>
                <p
                  ref={descriptionElRef}
                  className={cn('BotDetails__long-description', {
                    ['BotDetails__long-description--expanded']: isExpanded
                  })}
                >
                  {longDescription}
                </p>
                {isDescriptionTooLong && (
                  <Link
                    onClick={() => setIsExpanded((v) => !v)}
                    className="BotDetails__read-more-button"
                  >
                    {isExpanded ? 'Read less' : 'Read more'}
                  </Link>
                )}
              </>
            )
          ) : (
            <p className="BotDetails__long-description">
              <Skeleton width={'70%'} />
              <Skeleton width={'100%'} />
              <Skeleton width={'100%'} />
            </p>
          )}
        </div>
        <div className="BotDetails__body-chart">
          {bot && isTransactionsFetched && (
            <LineChart
              type="time"
              data={performanceMetrics}
              empty={performanceMetrics.length === 0}
              emptyStateMessage="No activity found"
              tooltipTitle={(i: number | string) => {
                // When axis is disabled, the property is item index instead item value
                if (i == null || performanceMetrics.length == 0) return '';
                return dayjs
                  .unix(Number(performanceMetrics[Number(i)].timestamp))
                  .format('MMM D, YYYY');
              }}
              tooltipSubTitle={() => 'Total transactions'}
              xAccessor="timestamp"
              lines={[
                {
                  strokeColor: bot?.enabled ? undefined : CssColor.Neutral30,
                  label: 'Scanned',
                  formatter: (val) => formatNumber(val) + ' txs',
                  yAccessor: 'value'
                }
              ]}
              minHeight={150}
              maxHeight={150}
              yAxisVisible={false}
              xAxisVisible={false}
              className="BotDetails__chart"
            />
          )}
        </div>
      </div>
      <div className="BotDetails__footer">
        {bot ? (
          <div className="BotDetails__links">
            {bot.promo_url && (
              <Button
                size="sm"
                variant="default"
                startIcon={ExternalLinkIcon}
                className="BotDetails__explore-product-button"
                href={sanitizeUrl(bot.promo_url)}
                target="_blank"
                onClick={() => {
                  trackEvent(
                    `explore_product_click_${slugify(bot.name || '', {
                      lower: true
                    })}`,
                    { bot_id: bot.id, promo_url: bot.promo_url }
                  );
                }}
              >
                Explore product
              </Button>
            )}
            <Button
              variant="default"
              size="sm"
              startIcon={RadioIcon}
              href={Routes.alerts.index({ agents: [bot?.id] })}
            >
              Go to alerts
            </Button>
            {repositoryHref && (
              <Button
                href={repositoryHref}
                target="_blank"
                size="sm"
                variant="default"
                startIcon={BranchIcon}
              >
                Source code
              </Button>
            )}
            {bot.license_url && (
              <Button
                variant="default"
                size="sm"
                startIcon={LicenseIcon}
                href={bot.license_url}
                target="_blank"
              >
                License
              </Button>
            )}
          </div>
        ) : (
          <div className="BotDetails__links">
            <Skeleton width={140} height={36} />
            <Skeleton width={140} height={36} />
            <Skeleton width={140} height={36} />
          </div>
        )}
        {bot ? (
          <div className="BotDetails__help">
            <div className="BotDetails__help-title">Need some help?</div>
            <Button
              variant="tertiary"
              size="md"
              startIcon={DiscordIcon}
              className="BotDetails__help-button"
              href={Routes.external.discord}
              target="_blank"
            >
              Forta Support
            </Button>
          </div>
        ) : (
          <div className="BotDetails__help">
            <Skeleton width={100} />
            <Skeleton width={120} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BotDetails;
